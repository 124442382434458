:root {
    --animation-duration: .25s;
    --animation-duration-fast: .15s;
    --animation-duration-fast-delay: .10s;
}

* {
    -webkit-tap-highlight-color: transparent;
}

html, body {
    margin: 0;
    height: 100%;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

#root {
    height: 100%;
}

.pageIntro {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}

.intro_logo {
    z-index: 200;
    max-width: min(325px, 90%);
}

.buttonIntro {
    background-color: black;
    border-radius: 100px;
    padding: 12px 40px 12px 40px;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;

    font-family: "Mukta", serif;
    font-weight: 600;
    font-style: normal;
}

.pageRoulette {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.list {
    position: relative;
    flex: 1;
    width: 100%;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
}

.selectedSnackWrapper {
    height: 170px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box; /* Ensures padding doesn't affect the height */

    /*Center on screen*/
    position: absolute;
    top: 50%;
    left: 50%;
    --translate-transform: translate(-50%, -50%);

    /*Animation setup*/
    transition: transform var(--animation-duration) ease-out;
    /* Default scale, overridden when setting the scale-normal/up classes.*/
    --scale-transform: scale(1);
    transform: var(--translate-transform) var(--scale-transform);
}

.selectedSnackImage {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.fade-in {
    opacity: 1;
    /*visibility: visible;*/
    transition: opacity var(--animation-duration) ease-in,
    visibility var(--animation-duration) ease-in;
}

.fade-in-fast-after-delay {
    opacity: 1;
    /*visibility: visible;*/
    transition: opacity var(--animation-duration-fast) ease-in var(--animation-duration-fast-delay),
    visibility var(--animation-duration-fast) ease-in var(--animation-duration-fast-delay);
}

.fade-out {
    opacity: 0;
    /*visibility: hidden; !* Ensures the element is non-interactive *!*/
    transition: opacity var(--animation-duration) ease-out,
    visibility var(--animation-duration) ease-out;
}

.fade-out-fast {
    opacity: 0;
    /*visibility: hidden; !* Ensures the element is non-interactive *!*/
    transition: opacity var(--animation-duration-fast) ease-out,
    visibility var(--animation-duration-fast) ease-out;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}

.scale-normal {
    --scale-transform: scale(1);
}

.scale-up {
    --scale-transform: scale(1.5);
}

.snackSelected {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 100px;

    text-align: center;
}

.snackIntro {
    font-family: "Kaisei HarunoUmi", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.6rem;
}

.snackName {
    font-family: "Kaisei HarunoUmi", serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.5rem;
}

.buttonReroll {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 100px;

    background-color: black;
    border-radius: 100px;
    padding: 12px 32px 12px 32px;
    font-size: 1.1rem;
    color: white;
    cursor: pointer;

    text-wrap: nowrap;

    font-family: "Mukta", serif;
    font-weight: 600;
    font-style: normal;
}
