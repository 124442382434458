.row {
  padding: 20px;
  box-sizing: border-box; /* Ensures padding doesn't affect the height */
}

.rowImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
